import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const aboutus = () => {
  return (
    <Layout>
      <SEO title='About Us' />

      <div class="c">
        <h1 class="center">About Us</h1>
        <p class="">
          CodeRescue is club of web developers to share their own styled
          components to build websites faster. CodeRescue is for the web
          professionals who need ready components for their use.
        </p>

        <p class="">
          We hope that you will definately like the components. If you are
          creative enough to generate new components, we would like to publish
          your components.
        </p>
        {/*<p class="">
          We love our rich and engaged community and our contributors, who are
          truly leaders in their field.
  </p>*/}
      </div>
    </Layout>
  )
}

export default aboutus
